<template>
	<div class="card card-flush pb-4 mr-2" style="height: 100%">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Purchase Orders
				<span>({{ dashboardDetail?.all_purchase_order_counts }})</span>
			</div>
			<div style="width: 150px">
				<v-select
					filled
					v-model="currentStatus"
					:items="timeDurationList"
					item-text="text"
					text-value="value"
					@change="changePurchaseOrderDetail"
				></v-select>
			</div>
		</div>
		<div class="mx-4 py-2" v-if="dashboardDetail.all_purchase_order_counts">
			<div id="chart">
				<apexchart type="pie" width="480" :options="chartOptions" :series="series"></apexchart>
			</div>
		</div>
		<div v-else style="min-height: 350px" class="d-flex justify-center align-center">
			<p class="m-0 row-not-d text-center py-3">
				<img
					style="width: 45px; margin-right: 4px"
					:src="$assetURL('media/error/empty.png')"
					class="row-not-found-image"
				/>
				Uhh... There are no purchase order at the moment.
			</p>
		</div>
	</div>
</template>

<script>
import { changeDashboardDetailStatus } from "@/core/lib/common.lib";
import ListingMixin from "@/core/mixins/listing.mixin";

import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "task-stats",
	mixins: [ListingMixin],
	data() {
		return {
			currentStatus: "all",
			series: [],
			timeDurationList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "This Week",
					value: "week",
				},
				{
					text: "This Month",
					value: "month",
				},
				{
					text: "Last 3 Month",
					value: "last-3month",
				},
			],

			chartOptions: {
				chart: {
					width: 450,
					type: "pie",
				},
				tooltip: {
					enabled: false,
				},
				legend: {
					show: true,
					formatter: function (seriesName, opts) {
						return [seriesName, ": ", opts.w.globals.series[opts.seriesIndex]];
					},
				},
				dataLabels: {
					formatter(val) {
						if ([10, 20, 30, 40, 50, 60, 70, 80, 90, 100].includes(val)) {
							return [Math.round(val) + "%"];
						} else {
							return [val.toFixed(1) + "%"];
						}
					},
				},
				labels: ["Draft", "Approved", "Rejected", "Sent For Approval"],
				responsive: [
					{
						breakpoint: 480,
						options: {
							chart: {
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				colors: ["#2196F3", "#4CAF50", "#B71C1C", "#F57C00"],
			},
		};
	},
	methods: {
		async changePurchaseOrderDetail() {
			try {
				const query = {
					filter: this.currentStatus,
				};
				this.pageLoading = true;
				const { purchase_orders_analysis_graph } = await changeDashboardDetailStatus(
					"purchase-orders",
					query
				);
				this.series = [
					purchase_orders_analysis_graph.draft_purchase_orders_count,
					purchase_orders_analysis_graph.approved_purchase_orders_count,
					// purchase_orders_analysis_graph.closed_purchase_orders_count,
					// purchase_orders_analysis_graph.cancelled_purchase_orders_count,
					purchase_orders_analysis_graph.rejected_purchase_orders_count,
					purchase_orders_analysis_graph.sent_for_approval_purchase_orders_count,
				];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	mounted() {
		this.series = [
			this.dashboardDetail.purchase_orders_analysis_graph.draft_purchase_orders_count,
			this.dashboardDetail.purchase_orders_analysis_graph.approved_purchase_orders_count,
			// this.dashboardDetail.purchase_orders_analysis_graph.closed_purchase_orders_count,
			// this.dashboardDetail.purchase_orders_analysis_graph.cancelled_purchase_orders_count,
			this.dashboardDetail.purchase_orders_analysis_graph.rejected_purchase_orders_count,
			this.dashboardDetail.purchase_orders_analysis_graph.sent_for_approval_purchase_orders_count,
		];
	},
};
</script>
