<template>
	<div class="card card-flush pb-4 mr-2" style="min-height: 100%">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Projects
				<span>({{ allProjectCount }})</span>
			</div>
			<div style="width: 150px">
				<v-select
					filled
					v-model="currentStatus"
					:items="timeDurationList"
					item-text="text"
					text-value="value"
					@change="changePurchaseOrderDetail"
				></v-select>
			</div>
		</div>
		<div class="mx-4 py-2" v-if="allProjectCount">
			<div id="chart">
				<apexchart type="pie" :options="chartOptions" width="450" :series="series"></apexchart>
			</div>
		</div>
		<div v-else style="min-height: 350px" class="d-flex justify-center align-center">
			<p class="m-0 row-not-d text-center py-3">
				<img
					style="width: 45px; margin-right: 4px"
					:src="$assetURL('media/error/empty.png')"
					class="row-not-found-image"
				/>
				Uhh... There are no project at the moment.
			</p>
		</div>
	</div>
</template>

<script>
import { changeDashboardDetailStatus } from "@/core/lib/common.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "task-stats",
	data() {
		return {
			series: [],
			currentStatus: "all",
			allProjectCount: null,
			timeDurationList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "This Week",
					value: "week",
				},
				{
					text: "This Month",
					value: "month",
				},
				{
					text: "Last 3 Month",
					value: "last-3month",
				},
			],
			chartOptions: {
				chart: {
					width: 450,
					type: "pie",
					// formatter: function (val) {
					// 	return val + "%";
					// },
				},
				labels: ["Completed", "In Progress", "Draft"],
				colors: ["#4CAF50", "#FF9800", "#2196F3"],
				legend: {
					show: true,
					formatter: function (seriesName, opts) {
						return [seriesName, ": ", opts.w.globals.series[opts.seriesIndex]];
					},
				},
				tooltip: {
					enabled: false,
				},
				// theme: {
				// 	mode: "light",
				// 	palette: "palette1",
				// 	monochrome: {
				// 		enabled: false,
				// 		color: "#255aee",
				// 		shadeTo: "light",
				// 		shadeIntensity: 0.65,
				// 	},
				// },
				plotOptions: {
					pie: {
						dataLabels: {
							offset: -25,
						},
					},
				},

				// title: {
				// 	text: "Monochrome Pie",
				// },
				dataLabels: {
					formatter(val) {
						if ([10, 20, 30, 40, 50, 60, 70, 80, 90, 100].includes(val)) {
							return [Math.round(val) + "%"];
						} else {
							return [val.toFixed(1) + "%"];
						}
					},
				},
			},
		};
	},
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		dayTimeClicked(index) {
			this.activeTab = index;
		},
		async changePurchaseOrderDetail() {
			try {
				const query = {
					filter: this.currentStatus,
				};
				this.pageLoading = true;
				const { project_analysis_graph } = await changeDashboardDetailStatus("projects", query);
				this.allProjectCount = project_analysis_graph?.all_project_count;
				this.series = [
					project_analysis_graph.completed_project_count,
					project_analysis_graph.inprogres_project_count,
					project_analysis_graph.draft_project_count,
				];
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	mounted() {
		this.series = [
			this.dashboardDetail.project_analysis_graph.completed_project_count,
			this.dashboardDetail.project_analysis_graph.inprogres_project_count,
			this.dashboardDetail.project_analysis_graph.draft_project_count,
		];
		this.allProjectCount = this.dashboardDetail.project_analysis_graph?.all_project_count;
	},
};
</script>
