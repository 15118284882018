<template>
	<div class="card card-flush pb-4">
		<div class="px-4 py-4 dashboard-card-header d-flex justify-space-between">
			<div class="mb-2 d-flex align-center font-level-6-bold" style="font-weight: 600; color: #0d47a1">
				Quotations
			</div>
			<div style="width: 200px">
				<v-select
					filled
					v-model="currentStatus"
					:items="statusList"
					item-text="text"
					text-value="value"
					@change="changeStatus"
				>
					<template v-slot:item="data">
						<!-- <template v-if="typeof data.item !== 'object'">
							<v-list-item-content v-text="data.item"></v-list-item-content>
						</template> -->
						<template>
							<v-list-item-avatar style="padding: 0px" size="20" dense>
								<v-icon :color="getStatusColor(data.item.value)">mdi-circle-medium</v-icon>
							</v-list-item-avatar>
							<v-list-item-content style="padding: 0px" dense>
								<v-list-item-title style="padding: 0px" v-html="data.item.text"></v-list-item-title>
							</v-list-item-content>
						</template>
					</template>
				</v-select>
			</div>
		</div>
		<div v-if="!pageLoading" class="taskListing mx-3">
			<table class="table table-row-dashed align-middle my-0">
				<thead style="background-color: #f7fafc">
					<tr>
						<th class="">#</th>
						<!-- <th class="">Action</th> -->
						<th class="" width="120px">Quotation #</th>
						<th class="">Details</th>
						<th class="">Customer</th>
						<!-- <th class="">Contact Person</th>
						<th class="">Billing Address</th> -->
						<th class="">Project</th>
						<th class="">Created AT</th>
					</tr>
				</thead>
				<tbody v-if="quotationList.length">
					<tr
						class="cursor-pointer"
						v-for="(row, index) in quotationList"
						:key="index"
						@click="goToQuotation(row.uuid)"
					>
						<td class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
						<td width="50px" class="td_column cursor-default simple-table-td">
							<div class="d-flex align-content-start flex-wrap align-start flex-column my-1">
								<div>
									<Chip
										tooltip
										tooltip-text="quotation #"
										small
										:text="row.barcode"
										color="blue darken-4"
									></Chip>
								</div>
								<Chip
									class="mt-1"
									tooltip
									tooltip-text="status"
									small
									:text="row.status_value"
									:color="getStatusColor(row.status_value)"
								></Chip>
							</div>
						</td>

						<td class="td_column cursor-default simple-table-td">
							<div>
								<span class="fw-500">Tittle : </span>
								<ShowValue :object="row" object-key="title" label="title"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Reference : </span>
								<ShowValue :object="row" object-key="reference" label="Reference"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Prepared By : </span>
								<ShowValue :object="row" object-key="prepared_by" label="Prepared By"></ShowValue>
							</div>

							<div>
								<span class="fw-500">Sales Person : </span>
								<ShowValue :object="row" object-key="sales_person" label="Sale Person"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Amount : </span>
								<ShowPrice :object="row" object-key="total" label="Amount"></ShowPrice>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<v-icon small left>mdi-account</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer company name"
									:object="row"
									object-key="customer_company_name"
									label="customer company name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-home-city-outline</v-icon>
								<ShowValue
									tooltip
									tooltip-text="customer name"
									:object="row"
									object-key="customer_name"
									label="Customer Name"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-email</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="customer email"
									:object="row"
									object-key="customer_email"
									label="customer email"
								></ShowValue>
							</div>
							<div>
								<v-icon small left>mdi-phone</v-icon>
								<ShowValue
									tooltip
									click-to-copy
									tooltip-text="Customer Phone"
									:object="row"
									object-key="customer_phone"
									label="Customer Phone"
								>
								</ShowValue>
							</div>
						</td>
						<td>
							<div>
								<span class="fw-500">Name : </span>
								<ShowValue :object="row" object-key="project_name" label="title"></ShowValue>
							</div>
							<div>
								<span class="fw-500">Price : </span>
								<ShowPrice
									class="fw-600"
									:object="row"
									object-key="project_price"
									label="price"
								></ShowPrice>
							</div>
							<div>
								<span class="fw-500">Start : </span>
								<template v-if="row.project_start_date">
									{{ formatDate(row.project_start_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">End : </span>
								<template v-if="row.project_end_date">
									{{ formatDate(row.project_end_date) }}
								</template>
								<template v-else>
									<em class="text-muted">no start date</em>
								</template>
							</div>
							<div>
								<span class="fw-500">Status : </span>
								<span v-if="row.project_status_value">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<v-chip
												:color="getStatusColor(row.project_status_value || 'draft')"
												label
												outlined
												v-if="row.project_status_value"
												small
												v-on="on"
												v-bind="attrs"
												class="white--text"
											>
												<span class="text-uppercase">
													{{ row.project_status_value }}
												</span>
											</v-chip>
										</template>
										<span>Project Status</span>
									</v-tooltip>
								</span>
								<span v-else>
									<em class="text-muted fw-400">No status</em>
								</span>
							</div>
						</td>
						<td class="td_column cursor-default simple-table-td">
							<div>
								<Chip
									tooltip
									tooltip-text="Created By"
									small
									:text="row.created_by"
									color="blue darken-4 white--text"
									class="mb-1"
								></Chip>
							</div>
							<TableDateTime
								small
								v-if="row.created_at"
								:human-format="row.added_at"
								:system-format="row.created_at"
							></TableDateTime>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="11">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no quotation at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
			<v-row class="my-2 d-flex justify-end">
				<v-col md="6" class="text-right">
					<v-pagination
						v-if="quotationList.length"
						color="blue darken-4"
						v-model="currentPage"
						:length="totalPage"
						total-visible="7"
						v-on:input="getProjectList()"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
		<div v-else class="mx-20 my-40 text-center h-100">
			<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
		</div>
	</div>
</template>

<script>
import ShowValue from "@/view/components/ShowValue.vue";
import ShowPrice from "@/view/components/ShowPrice.vue";
import { SET_ERROR } from "@/core/services/store/common.module";
import { getAllQuotation } from "@/core/lib/project.lib";
import TableDateTime from "@/view/components/TableDateTime";
import Chip from "@/view/components/Chip";
export default {
	name: "quotation-stats",
	components: {
		ShowValue,
		Chip,
		// Status,
		ShowPrice,
		TableDateTime,
		// ShowTextAreaValue,
	},
	data() {
		return {
			pageLoading: false,
			quotationList: [],
			currentStatus: "all",
			currentPage: 1,
			totalPage: null,
			statusList: [
				{
					text: "All",
					value: "all",
				},
				{
					text: "Draft",
					value: "draft",
				},
				{
					text: "Approved",
					value: "approved",
				},
				// {
				// 	text: "Cancelled",
				// 	value: "cancelled",
				// },
				{
					text: "Rejected",
					value: "rejected",
				},
				{
					text: "Sent For Approval",
					value: "sent_for_approval",
				},
			],
		};
	},
	computed: {
		priorityText: function () {
			return function name(row) {
				return row.priority === 1 ? "low" : row.priority === 2 ? "medium" : "high";
			};
		},
		priorityColor: function () {
			return function name(row) {
				return row.priority === 1
					? "grey--text lighten-1"
					: row.priority === 2
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusColor: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "green--text"
					: row.status_value === "in-progress"
					? "blue--text"
					: "red--text";
			};
		},
		taskStatusBorder: function () {
			return function name(row) {
				return row.status_value === "completed"
					? "border:1px solid #4CAF50"
					: row.status_value === "in-progress"
					? "border:1px solid #2196F3"
					: "border:1px solid #F44336";
			};
		},
	},
	methods: {
		getTwoCharacter(word) {
			if (word) {
				return word.slice(0, 2).toUpperCase();
			}
			return "";
		},
		goToQuotation(uuid) {
			this.$router.push({
				path: `/quotation/${uuid}`,
			});
		},
		changeStatus(item) {
			this.currentStatus = item;
			this.getProjectList();
		},
		async getProjectList() {
			// 	ApiService.get(`dashboard/all`)
			// 	.then(({ data }) => {
			// 		this.dashboardDetail = data;
			// 		// console.log(this.dashboardDetail);
			// 	})
			// 	.catch(({ response }) => {
			// 		console.error({ response });
			// 	}).finally;
			// {
			// }
			try {
				this.pageLoading = true;
				const { tbody, total_page } = await getAllQuotation({
					page: this.currentPage,
					per_page: 5,
					status: this.currentStatus,
				});
				this.quotationList = [...tbody];
				this.totalPage = total_page;
				// _this.$store.commit(SET_MESSAGE, [{ model: true, message: "Success ! Task has been ." }]);
			} catch (error) {
				// console.log(object);
				this.$store.commit(SET_ERROR, [{ model: true, message: "something went wrong!" }]);
			} finally {
				this.pageLoading = false;
			}
		},
	},
	async mounted() {
		await this.getProjectList();
	},
};
</script>
