<template>
	<v-row>
		<v-col md="12" class="d-flex justify-space-between">
			<!-- <router-link :to="{ name: 'quotation' }"> -->
			<v-col
				v-if="getPermission('customer::view')"
				md="2"
				@click="goToListingPage('customer')"
				class="box-shadow mr-6 cursor-pointer"
				style="background-color: #e06469"
			>
				<div class="p-1 text-white">
					<div class="d-flex justify-space-between">
						<span class="font-level-5-bold">Customer </span>
					</div>
					<div class="d-flex align-items-center justify-space-between mt-4">
						<div class="dashboard-summary-heading">
							{{ dashboardDetail?.all_customer_counts }}
						</div>
						<v-icon size="45" color="white">mdi-account-supervisor</v-icon>
					</div>
				</div>
			</v-col>
			<!-- </router-link> -->
			<v-col
				v-if="getPermission('purchase-order::view')"
				@click="goToListingPage('purchase-order')"
				md="2"
				class="box-shadow mr-6 cursor-pointer"
				style="background-color: #8294c4"
			>
				<div class="p-1 text-white">
					<div class="d-flex justify-space-between">
						<span class="font-level-5-bold"> Purchase Order </span>
					</div>
					<div class="d-flex align-items-center justify-space-between mt-4">
						<div class="dashboard-summary-heading">
							{{ dashboardDetail?.all_purchase_order_counts }}
						</div>
						<v-icon size="45" color="white">mdi-receipt</v-icon>
					</div>
				</div>
			</v-col>
			<v-col
				v-if="getPermission('quotation::view')"
				md="2"
				@click="goToListingPage('quotation')"
				class="box-shadow mr-6 cursor-pointer"
				style="background-color: #a0d8b3"
			>
				<div class="p-1 text-white">
					<div class="d-flex justify-space-between">
						<span class="font-level-5-bold"> Quotation </span>
					</div>
					<div class="d-flex align-items-center justify-space-between mt-4">
						<div class="dashboard-summary-heading">
							{{ dashboardDetail?.all_quotation_counts }}
						</div>
						<!-- <v-icon size="45" color="white">mdi-account-supervisor</v-icon> -->
						<inline-svg
							style="height: 45px; width: 45px"
							fill="#ffff"
							:src="$assetURL('media/custom-svg/quotation-icon.svg')"
						/>
					</div>
				</div>
			</v-col>
			<v-col
				v-if="getPermission('project::view')"
				md="2"
				@click="goToListingPage('project')"
				class="box-shadow mr-6 cursor-pointer"
				style="background-color: #7c96ab"
			>
				<div class="p-1 text-white">
					<div class="d-flex justify-space-between">
						<span class="font-level-5-bold"> Project </span>
					</div>
					<div class="d-flex align-items-center justify-space-between mt-4">
						<div class="dashboard-summary-heading">
							{{ dashboardDetail?.all_project_counts }}
						</div>
						<!-- <v-icon size="45" color="white">mdi-account-supervisor</v-icon> -->
						<inline-svg
							style="height: 45px; width: 45px"
							fill="#ffff"
							:src="$assetURL('media/custom-svg/project1.svg')"
						/>
						<!-- <v-progress-circular rotate="90" size="55" width="5" value="65" color="white"
							><span class="white--text">65%</span></v-progress-circular
						> -->
					</div>
					<!-- <div class="mt-2">
						<div class="font-level-1-bold d-flex justify-space-between">
							<span>43 Pending</span>
							<span>72%</span>
						</div>
						<v-progress-circular rotate="90" size="100" width="15" value="45" color="red">
							45
						</v-progress-circular>
					</div> -->
				</div>
			</v-col>

			<v-col
				v-if="getPermission('task::view')"
				md="2"
				@click="scrollIntoView"
				class="box-shadow mr-6 cursor-pointer"
				style="background-color: #a0d8b3"
			>
				<div class="p-1 text-white">
					<div class="d-flex justify-space-between">
						<span class="font-level-5-bold"> Task </span>
						<!-- <div class="ml-2">
							<v-icon color="white">mdi-trending-up</v-icon>

							<span> 5<v-icon size="13" color="white">mdi-percent</v-icon></span>
						</div> -->
					</div>
					<div class="d-flex align-items-center justify-space-between mt-4">
						<div class="dashboard-summary-heading">
							{{ dashboardDetail?.all_task_counts }}
						</div>
						<!-- <v-icon size="45" color="white">mdi-account-supervisor</v-icon> -->
						<inline-svg
							style="height: 40px; width: 40px"
							fill="#ffff"
							:src="$assetURL('media/custom-svg/task3.svg')"
						/>
					</div>
				</div>
			</v-col>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "dashboard-header",
	props: {
		dashboardDetail: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		goToListingPage(name) {
			this.$router.push({ name: name });
		},
		scrollIntoView() {
			this.$emit("scrollIntoView");
			// console.log("scrollInotView");
		},
	},
	// mounted() {
	// 	console.log(this.dashboardDetail);
	// },
};
</script>
